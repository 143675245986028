import React, { useMemo } from 'react';
import { Box, createStyles, makeStyles, Switch, Theme, Typography } from '@material-ui/core';
import DataTable from 'shared/widgets/dataTable';
import Paper from '@material-ui/core/Paper';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import PrivateComponent from 'shared/auth/privateComponent';
import { DeviceAutoOrder, GroupAutoOrder } from 'shared/model/autoOrder.model';
import { useDispatch, useSelector } from 'react-redux';
import { activateDeviceAutoOrder } from 'shared/reducers/autoOrdersSlice';
import { IRootState } from 'config/store';

export interface AutoOrderDetailsProps {
  data: GroupAutoOrder;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100vw - 90px)',
      [theme.breakpoints.down('sm')]: {
        width: '100vw'
      }
    },
    leftMenuOpen: {
      width: 'calc(100vw - 235px)'
    },
    title: {
      fontWeight: 'bold',
      '&>svg': {
        verticalAlign: 'middle',
        marginRight: theme.spacing(1)
      }
    },
    icon: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1)
    },
    warningText: {
      color: theme.palette.error.main,
      textAlign: 'center',
      marginLeft: theme.spacing(1)
    },
    warningBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    }
  })
);

const AutoOrderDetails = (props: AutoOrderDetailsProps) => {
  const { data } = props;
  const classes = useStyles();
  const leftMenuOpen = useSelector(({ layout }: IRootState) => layout.leftMenuOpen);
  const autoOrders = data.device_auto_orders;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns: IDataTableColumn<DeviceAutoOrder>[] = useMemo(() => {
    const handleStatusChange = (deviceAutoOrder: DeviceAutoOrder) => () => {
      dispatch(
        activateDeviceAutoOrder({ ...deviceAutoOrder, is_active: !deviceAutoOrder.is_active })
      );
    };

    return [
      {
        id: 'siloName',
        selector: row => row.device_name,
        name: t('silo_plural'),
        grow: 2
      },
      {
        selector: row => row.capa_max,
        name: t('capacity'),
        sortable: true,
        format: row => t('number_workspace_filling_unit', { value: row.capa_max }),
        grow: 1
      },
      {
        selector: row => row.device_content,
        name: t('device_content'),
        sortable: true,
        format: row => {
          const deviceContent = row?.device_content ?? '';
          return !deviceContent ? (
            <Box className={classes.warningBox}>
              <WarningIcon fontSize="small" color="error" />
              <Typography className={classes.warningText} variant="body2">
                {t('missing_content')}
              </Typography>
            </Box>
          ) : (
            <>{deviceContent}</>
          );
        },
        grow: 2
      },
      {
        selector: row => row.device_content_reference,
        name: t('reference'),
        sortable: true,
        format: row => {
          const productNumber = row?.device_content_reference ?? '';
          return !productNumber ? (
            <Box className={classes.warningBox}>
              <WarningIcon fontSize="small" color="error" />
              <Typography className={classes.warningText} variant="body2">
                {t('missing_content_reference')}
              </Typography>
            </Box>
          ) : (
            <>{productNumber}</>
          );
        },
        grow: 2
      },
      {
        selector: row => row.last_autoorder_date,
        name: t('last_trigger'),
        sortable: true,
        grow: 2,
        format: row => {
          if (row.last_autoorder_date) {
            const humanize = row.last_autoorder_date.fromNow();
            const dateStr = formatDate(row.last_autoorder_date, APP_TIMESTAMP_FORMAT);
            return `${humanize} - ${dateStr}`;
          }
          return null;
        }
      },
      {
        selector: row => row.is_active,
        name: t('active'),
        sortable: true,
        grow: 0,
        format: row => {
          return (
            <div style={{ textAlign: 'right' }}>
              <PrivateComponent
                resource="AutoOrderActive"
                operation={['UPDATE']}
                otherwise={<Switch checked={row.is_active} disabled color="primary" />}
              >
                <Switch
                  checked={row.is_active}
                  color="primary"
                  disabled={
                    !row.is_active && (!row.device_content_reference || !row.device_content)
                  }
                  onChange={handleStatusChange(row)}
                />
              </PrivateComponent>
            </div>
          );
        }
      }
    ];
  }, [t, dispatch, classes.warningBox, classes.warningText]);

  if (!data) {
    return null;
  }

  return (
    <Box className={clsx(classes.root, { [classes.leftMenuOpen]: leftMenuOpen })} p={2} pl={8}>
      <Box>
        <Box minHeight="100px">
          <Paper elevation={4}>
            <Box p={1}>
              <DataTable
                noHeader
                columns={columns}
                data={autoOrders}
                defaultSortField="siloName"
                defaultSortAsc={false}
                paginationPerPage={20}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default AutoOrderDetails;
