import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BarChartIcon from '@material-ui/icons/BarChart';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CloseIcon from '@material-ui/icons/FormatIndentDecrease';
import OpenIcon from '@material-ui/icons/FormatIndentIncrease';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LanguageIcon from '@material-ui/icons/Language';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PeopleIcon from '@material-ui/icons/People';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import clsx from 'clsx';
import { IRootState } from 'config/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'shared/reducers/authenticationSlice';
import { openTermsOfUse, toggleLeftMenu } from 'shared/reducers/layoutSlice';
import { openChat } from 'shared/utils/chat.utils';
import {
  workspaceIsIbc,
  workspaceIsSilo,
  workspaceUseAutoOrders
} from 'shared/utils/workspace-utils';
import { HEADER_HEIGHT } from '../layout';
import MenuItem from './menuItem';
import { Assignment, LocalShipping } from '@material-ui/icons';
import { ReactComponent as SiloIconBold } from 'shared/icons/SiloIconBold.svg';
import { ReactComponent as IbcIconBold } from 'shared/icons/IbcIconBold.svg';

import { siloProductsTo } from 'modules/products';
import { siloOrdersTo } from 'modules/siloOrders';

export const LEFT_MENU_WIDTH = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: LEFT_MENU_WIDTH,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerPaper: {
      width: LEFT_MENU_WIDTH,
      marginTop: HEADER_HEIGHT,
      height: `calc(100% - ${HEADER_HEIGHT}px)`
    },
    drawerOpen: {
      width: LEFT_MENU_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(7),
      '& .MuiListItemText-root': {
        // Prevent long text from wrapping and making the icons larger when closed
        whiteSpace: 'nowrap,'
      },
      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(0)
      }
    },
    menu: {
      paddingTop: theme.spacing(1),
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      overflow: 'hidden'
    },
    openMenuIcon: {
      minWidth: theme.spacing(5)
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    root: {
      '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected,
        '&:hover': {
          backgroundColor: theme.palette.action.hover
        }
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
    icon: {
      color: 'inherit'
    },
    selectedIcon: {
      color: theme.palette.primary.main
    },
    svgIcon: {
      width: '24px',
      height: '24px',
      fill: 'inherit'
    }
  })
);

function MainMenuItems({
  isSilo,
  isIbc,
  isInstaller,
  useAutoOrders
}: {
  isInstaller: boolean;
  isSilo: boolean;
  isIndus: boolean;
  isIbc: boolean;
  useAutoOrders: boolean;
}) {
  if (isInstaller) {
    return null;
  }

  return (
    <>
      <MenuItem
        icon={<DashboardIcon />}
        primary="dashboard"
        to="/dashboards"
        resource="menu_dashboard"
        operation={['READ']}
      />
      <MenuItem
        icon={isIbc ? <IbcIconBold /> : <SiloIconBold />}
        primary={isIbc ? 'ibc' : 'silo'}
        to="/devices"
        resource="menu_devices"
        operation={['READ']}
      />
      <MenuItem
        icon={<PeopleIcon />}
        primary="user"
        to="/users"
        resource="menu_users"
        operation={['READ']}
      />
      {isSilo && (
        <MenuItem
          icon={<LocalShipping />}
          primary="orders.title"
          to={siloOrdersTo}
          resource="menu_deliveries"
          operation={['READ']}
        />
      )}
      <MenuItem
        icon={<NotificationsIcon />}
        primary="alert"
        to="/alerts"
        resource="menu_alerts"
        operation={['READ']}
      />
      {isIbc && (
        <MenuItem
          icon={<PlaylistAddCheckIcon />}
          primary="business_rules"
          to="/rules"
          resource="menu_rules"
          operation={['READ']}
        />
      )}
      {isIbc && (
        <MenuItem
          icon={<BarChartIcon />}
          primary="kpi"
          to="/kpi"
          resource="menu_kpi"
          operation={['READ']}
        />
      )}
      <MenuItem
        icon={<LanguageIcon />}
        primary="callbacks"
        to="/callbacks"
        resource="menu_callbacks"
        operation={['READ']}
      />
      <MenuItem
        icon={<GroupWorkIcon />}
        primary="group"
        to="/groups"
        resource="menu_groups"
        operation={['READ']}
      />
      {isSilo && (
        <MenuItem
          icon={<Assignment />}
          primary="products"
          to={siloProductsTo}
          resource="menu_products"
          operation={['READ']}
        />
      )}
      {useAutoOrders && (
        <MenuItem
          icon={<AutorenewIcon />}
          primary="auto_order"
          to="/auto-orders"
          resource="menu_auto_orders"
          operation={['READ']}
        />
      )}
    </>
  );
}

function SmallLayoutItems({
  onLogout,
  onOpenChat,
  onOpenTermsOfUse
}: {
  onLogout: () => void;
  onOpenChat: () => void;
  onOpenTermsOfUse: () => void;
}) {
  return (
    <>
      <MenuItem icon={<AccountBoxIcon />} primary="edit_profile" to="/user-profile" />
      <MenuItem icon={<VpnKeyIcon />} primary="edit_password" to="/user-password" />
      <MenuItem icon={<LiveHelpIcon />} primary="help" onClick={onOpenChat} />
      <MenuItem icon={<MenuBookIcon />} primary="menu_title" onClick={onOpenTermsOfUse} />
      <MenuItem icon={<ExitToAppIcon />} primary="log_out" onClick={onLogout} />
    </>
  );
}

const LeftMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(({ layout }: IRootState) => layout.leftMenuOpen);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);
  const isIbc = workspaceIsIbc(settings);
  const useAutoOrders = workspaceUseAutoOrders(settings);
  const me = useSelector(({ authentication }: IRootState) => authentication.me);
  const isInstaller = me?.workspace_role?.name?.trim().toLowerCase() === 'installer';

  const toggleMenu = () => {
    dispatch(toggleLeftMenu());
  };

  const collapse = t('collapse');
  const collapseTitle = open ? collapse : t('expand');

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
    >
      <Box className={classes.menu} display="flex" flexDirection="column">
        <Box flexGrow={1}>
          <MainMenuItems
            isInstaller={isInstaller}
            isSilo={isSilo}
            isIbc={isIbc}
            useAutoOrders={useAutoOrders}
          />
          {isSmall && (
            <>
              <Divider className={classes.divider} />
              <SmallLayoutItems
                onLogout={() => dispatch(logout())}
                onOpenChat={() => openChat()}
                onOpenTermsOfUse={() => dispatch(openTermsOfUse())}
              />
            </>
          )}
        </Box>

        <ListItem button onClick={toggleMenu} title={collapseTitle}>
          <ListItemIcon className={classes.openMenuIcon}>
            {open ? <CloseIcon /> : <OpenIcon />}
          </ListItemIcon>
          <ListItemText primary={collapse} />
        </ListItem>
      </Box>
    </Drawer>
  );
};

export default LeftMenu;
