import Box from '@material-ui/core/Box';
import { IRootState } from 'config/store';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { nanolikeDataType } from 'shared/model/api.model';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { workspaceIsIbc, workspaceIsSilo } from 'shared/utils/workspace-utils';
import SingleAutoComplete from 'shared/widgets/form/singleAutocomplete';
import SelectLocation from './selectLocation';
import SelectMax from './selectMax';
import SelectMin from './selectMin';
import SelectMinMax from './selectMinMax';
import SelectTimeThreshold from './selectTimeThreshold';
import { useLocalizedDataTypeWithUnit } from 'shared/utils/lang-utils';
import validateRequired from 'shared/widgets/form/validateRequired';
import { Grid } from '@material-ui/core';

const ibcDataTypeAlertSubSet: nanolikeDataType[] = [
  'level_percent',
  'remainingDays',
  'lastMessageReceived',
  'level_liter',
  'missingLiter',
  'temperature',
  'position'
];

const siloDataTypeAlertSubset: nanolikeDataType[] = ['level_percent', 'remainingDays', 'level_t'];
const indusDataTypeAlertSubset: nanolikeDataType[] = ['level_percent', 'missingWeight', 'level_t'];

const getAvailableTypes = (isIbc: boolean, isSilo: boolean) =>
  isIbc ? ibcDataTypeAlertSubSet : isSilo ? indusDataTypeAlertSubset : siloDataTypeAlertSubset;

export interface IAlertFormSecondStepProps {
  step: number;
  activeStep: number;
}

const AlertFormSecondStep = (props: IAlertFormSecondStepProps) => {
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isIbc = workspaceIsIbc(settings);
  const isSilo = workspaceIsSilo(settings);
  const { activeStep, step } = props;
  const isActiveStep = activeStep === step;
  const { t } = useTranslation();
  const { localizedDataTypeWithUnit } = useLocalizedDataTypeWithUnit();
  const { watch, getValues } = useFormContext();
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new' ? true : false;

  const selectedDataType = watch<string, ILabelValueOption>('data_type');
  const dataTypes = getAvailableTypes(isIbc, isSilo).map((item: nanolikeDataType) => ({
    label: localizedDataTypeWithUnit(item),
    value: item
  }));

  const defaultValue = !isNew ? getValues().data_type : undefined;

  return (
    <Box display={isActiveStep ? 'block' : 'none'} marginBottom={4}>
      <SingleAutoComplete
        name="data_type"
        label={t('data_type')}
        defaultValue={defaultValue}
        options={dataTypes}
        validate={(value: any) => isActiveStep && validateRequired(value)}
      />
      {selectedDataType && (
        <Box>
          {(() => {
            switch (selectedDataType.value) {
              case 'position':
                return <SelectLocation isActive={isActiveStep} />;
              case 'lastMessageReceived':
                return <SelectTimeThreshold isActive={isActiveStep} />;
              case 'remainingDays':
                return (
                  <Grid container spacing={2} justify="center">
                    <Grid item xs={12} md={6} lg={4}>
                      <SelectMin isActive={isActiveStep} fullWidth margin="dense" />
                    </Grid>
                  </Grid>
                );
              case 'missingWeight':
                return (
                  <Grid container spacing={2} justify="center">
                    <Grid item xs={12} md={6} lg={4}>
                      <SelectMax isActive={isActiveStep} fullWidth margin="dense" />
                    </Grid>
                  </Grid>
                );
              default:
                return <SelectMinMax isActive={isActiveStep} />;
            }
          })()}
        </Box>
      )}
    </Box>
  );
};

export default AlertFormSecondStep;
