import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { IWorkspaceRole, IWorkspaceSetting } from 'shared/model/workspace.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { logout } from './authenticationSlice';
import { errorNotification } from './notifierSlice';

interface IWorkspaceRoleResponse {
  count: string;
  results: IWorkspaceRole[];
}

interface IWorkspaceSettingsResponse {
  count: number;
  results: IWorkspaceSetting[];
}

const initialState = {
  loadingRoles: false,
  loadingSettings: false,
  loadingDeviceContents: false,
  errorMessage: '',
  fetchRolesSuccess: false,
  fetchSettingsSuccess: false,
  wsSuccess: false,
  availableRoles: [] as IWorkspaceRole[],
  settings: [] as IWorkspaceSetting[]
};

export type WorkspaceState = typeof initialState;

export const slice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    fetchWorkspaceRolesStart: state => {
      state.loadingRoles = true;
      state.fetchRolesSuccess = false;
      state.availableRoles = [];
    },
    fetchWorkspaceRolesFailed: (state, action: PayloadAction<string>) => {
      state.loadingRoles = false;
      state.errorMessage = action.payload;
      state.fetchRolesSuccess = false;
      state.wsSuccess = false;
    },
    fetchWorkspaceRolesSuccess: (state, action: PayloadAction<IWorkspaceRole[]>) => {
      state.availableRoles = action.payload;
      state.loadingRoles = false;
      state.fetchRolesSuccess = true;
      if (state.fetchSettingsSuccess) {
        state.wsSuccess = true;
      }
    },
    fetchWorkspaceSettingsStart: state => {
      state.loadingSettings = true;
      state.fetchSettingsSuccess = false;
    },
    fetchWorkspaceSettingsFailed: (state, action: PayloadAction<string>) => {
      state.loadingSettings = false;
      state.errorMessage = action.payload;
      state.fetchSettingsSuccess = false;
      state.wsSuccess = false;
    },
    fetchWorkspaceSettingsSuccess: (state, action: PayloadAction<IWorkspaceSettingsResponse>) => {
      state.loadingSettings = false;
      state.fetchSettingsSuccess = true;
      state.settings = action.payload.results;
      if (state.fetchRolesSuccess) {
        state.wsSuccess = true;
      }
    },
    resetWorkspace: state => {
      state.loadingRoles = false;
      state.loadingSettings = false;
      state.loadingDeviceContents = false;
      state.fetchSettingsSuccess = false;
      state.fetchRolesSuccess = false;
      state.errorMessage = '';
      state.wsSuccess = false;
      state.availableRoles = [];
      state.settings = [];
    }
  }
});

export default slice.reducer;

export const { resetWorkspace } = slice.actions;

//Actions
const {
  fetchWorkspaceRolesStart,
  fetchWorkspaceRolesFailed,
  fetchWorkspaceRolesSuccess,
  fetchWorkspaceSettingsStart,
  fetchWorkspaceSettingsFailed,
  fetchWorkspaceSettingsSuccess
} = slice.actions;
const apiUrl = getEnvApiUrl();

const fetchWorkspaceRoles = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchWorkspaceRolesStart());
    const response: AxiosResponse<IWorkspaceRoleResponse> = await axios.get(
      `${apiUrl.replace('v1', 'v2')}/me/roles/`
    );
    dispatch(fetchWorkspaceRolesSuccess(response.data));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchWorkspaceRolesFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
    dispatch(logout());
  }
};

const fetchWorkspaceSettings = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchWorkspaceSettingsStart());
    const response: AxiosResponse<IWorkspaceSettingsResponse> = await axios.get(
      `${apiUrl}/workspace/settings/`
    );
    dispatch(fetchWorkspaceSettingsSuccess(response.data));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchWorkspaceSettingsFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
    dispatch(logout());
  }
};

export const fetchWorkspace = (): AppThunk => async dispatch => {
  await dispatch(fetchWorkspaceRoles());
  await dispatch(fetchWorkspaceSettings());
};
