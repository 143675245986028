import { IMe } from 'shared/model/user.model';
import { LANG_KEY } from './storage-utils';
import { displayUser } from './user-utils';
import { getEnvWorkspace } from '../../config/env';

export const hideChat = () => {
  // @ts-ignore
  window.$crisp.push(['do', 'chat:hide']);
};

export const showChat = () => {
  // @ts-ignore
  window.$crisp.push(['do', 'chat:show']);
};

export const openChat = () => {
  showChat();
  // @ts-ignore
  window.$crisp.push(['do', 'chat:open']);
};
export const openHelpCenter = (me: IMe) => {
  const userLanguage = me.configuration.preferred_language.split('-')[0];
  const supportedLanguage =
    ['en', 'fr', 'es', 'pt-br'].find(lang => lang.split('-')[0] === userLanguage) ?? 'en';

  window.open(`https://siloconnect.crisp.help/${supportedLanguage}`);
};

export const setUserForChat = (me: IMe) => {
  const email = me.email;
  const nickName = displayUser(me);

  // @ts-ignore
  $crisp.push(['set', 'user:email', email]);
  // @ts-ignore
  $crisp.push(['set', 'user:nickname', nickName]);
  changeChatLang(me.configuration.preferred_language);
  hideChat();
};

export const setUserWorkspaceChat = () => {
  // @ts-ignore
  $crisp.push(['set', 'session:data', [[['workspace', getEnvWorkspace()]]]]);
};

export const changeChatLang = (lang: string) => {
  if (window.localStorage.getItem(LANG_KEY) !== lang) {
    window.localStorage.setItem(LANG_KEY, lang);
  }
};
