import React from 'react';
import { useForm, FormContext, Controller } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'config/store';
import { IDevice } from 'shared/model/device.model';
import { ILabelValueOption } from 'shared/utils/select-utils';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  ListItemText,
  Box
} from '@material-ui/core';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';
import NanolikeDatePicker from 'shared/widgets/form/nanolikeDatePicker';
import SingleAutoComplete from 'shared/widgets/form/singleAutocomplete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Moment from 'react-moment';

export interface IDeviceFormResponse {
  groups: ILabelValueOption<string>[];
  device_name: string;
  device_description: string;
  device_content: ILabelValueOption<string>;
  device_install_date: Moment;
  device_fabrication_date: Moment;
  device_next_maintenance_date: Moment;
  galileou_enabled: boolean;
}

export interface IDeviceFormProps {
  device: IDevice;
  onSubmit: (data: IDeviceFormResponse) => void;
  onCancel: () => void;
  editContentOnly?: boolean;
}

const DeviceForm: React.FC<IDeviceFormProps> = ({
  device,
  onSubmit,
  onCancel,
  editContentOnly
}) => {
  const { t } = useTranslation();
  const groups = useSelector(({ group }: IRootState) => group.groups);
  let products = useSelector(({ product }: IRootState) => product.products);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const updating = useSelector(({ devices }: IRootState) => devices.updating);

  const form = useForm<IDeviceFormResponse>({
    defaultValues: {
      device_name: device.device_name,
      device_description: device.device_description,
      groups: device.group_ids
        ? device.group_ids.map(aGroupId => ({
            label: groups?.find(group => group.group_id === aGroupId)?.group_name || '',
            value: aGroupId
          }))
        : [],
      device_content: device.device_content_id
        ? {
            label: products?.find(dc => dc.id === device.device_content_id)?.device_content || '',
            value: device.device_content_id
          }
        : undefined,
      galileou_enabled: device.galileou_enabled
    }
  });

  const defaultProduct = products.find(product => product.id === device.device_content_id);

  // Case where contents displayed must belong to the device group, we reuse content from store instead of loading again from API.
  if (settings.find(s => s.key === 'filterContentByGroup')?.value === 'true') {
    products = products.filter(d =>
      d.groups.map(g => g.group_id).some(g => device.group_ids?.includes(g))
    );
  }

  const groupOptions: ILabelValueOption[] = groups.map(aGroup => ({
    label: aGroup.group_name,
    value: aGroup.group_id
  }));

  const productsOptions: ILabelValueOption[] = products.map(dc => ({
    label: dc.device_content,
    value: dc.id
  }));

  const defaultProductOption = defaultProduct
    ? { label: defaultProduct?.device_content, value: defaultProduct?.id }
    : undefined;

  return (
    <FormContext {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {t('edit_device')} {device.device_name}
        </DialogTitle>
        <DialogContent dividers>
          {!editContentOnly && <Typography>{t('edit_device_popup_lower_text')}</Typography>}
          <TextField
            autoFocus
            margin="dense"
            id="device_name"
            label={t('device_name')}
            fullWidth
            name="device_name"
            disabled={editContentOnly || updating}
            inputRef={form.register({
              required: <Trans i18nKey="required_field">Required Field</Trans>
            })}
            error={!!form.errors.device_name}
            helperText={form.errors.device_name && form.errors.device_name.message}
          />
          <Box
            mb={2}
            style={{
              // Hide the field, but keep it so that the form has the correct values
              display: editContentOnly ? 'none' : 'block'
            }}
          >
            <Grid container spacing={2} justify="center">
              <Grid item xs={12} md={6}>
                <NanolikeDatePicker
                  name="device_install_date"
                  value={device.device_install_date as Moment}
                  label={t('device_starting_date')}
                  disabled={true}
                  disableFuture
                />
              </Grid>
              {!device.is_silo && (
                <>
                  <Grid item xs={12} md={4}>
                    <NanolikeDatePicker
                      name="device_fabrication_date"
                      value={device.device_fabrication_date as Moment}
                      label={t('device_fabrication_date')}
                      disabled={updating}
                      disableFuture
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <NanolikeDatePicker
                      name="device_next_maintenance_date"
                      value={device.device_next_maintenance_date as Moment}
                      label={t('device_next_maintenance_date')}
                      disabled={updating}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <MultipleAutoComplete
              label={t('select_group')}
              name="groups"
              defaultValue={form.getValues('groups')}
              options={groupOptions}
              validate={(value: any) => {
                if (!value || value.length === 0) {
                  return <Trans i18nKey="required_field">Required Field</Trans>;
                }
                return true;
              }}
            />
          </Box>
          {device.is_silo ? (
            <SingleAutoComplete
              label={t('device_content')}
              name="device_content"
              defaultValue={defaultProductOption}
              options={productsOptions}
              disableClearable={false}
            />
          ) : (
            <FormControlLabel
              control={
                <Controller
                  control={form.control}
                  name="galileou_enabled"
                  render={({ onChange, value }) => (
                    <Checkbox onChange={e => onChange(e.target.checked)} checked={value} />
                  )}
                />
              }
              label={
                <ListItemText
                  primary={t('automaticPoiAssignationPrimaryText')}
                  secondary={t('automaticPoiAssignationSecondaryText')}
                />
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} startIcon={<CancelIcon />}>
            {t('cancel')}
          </Button>
          <Button type="submit" color="primary" startIcon={<SaveIcon />}>
            {t('save')}
          </Button>
        </DialogActions>
      </form>
    </FormContext>
  );
};

export default DeviceForm;
